<template>
  <div class="report-issue-container">
    <transition name="fade-out" @leave="formLeave">
      <ReportIssueForm @FORM-SUBMITTED="onFormSubmit" v-if="!formSubmitted" />
    </transition>
    <transition name="fade-in" @enter="successEnter" @before-enter="successBeforeEnter">
      <ReportIssueSuccess :formData="formData" v-if="showSuccess" />
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import gsap from 'gsap'
import ReportIssueForm from '@/components/reportIssue/ReportIssueForm'
import ReportIssueSuccess from '@/components/reportIssue/ReportIssueSuccess'

export default {
  name: 'ReportIssue',
  components: {
    ReportIssueForm,
    ReportIssueSuccess
  },
  setup () {
    const formSubmitted = ref(false)
    const showSuccess = ref(false)
    const formData = ref({})

    const onFormSubmit = (_formData) => {
      formSubmitted.value = true
      formData.value = _formData
    }

    const formLeave = (el, done) => {
      gsap.to('.right', { duration: 0.2, x: 100 })
      gsap.to('.left', { duration: 0.2, x: -100 })
      gsap.to('.text', { duration: 0.2, scale: 0 })
      gsap.to(el, {
        duration: 0.2,
        autoAlpha: 0,
        onComplete: function () {
          done()
          showSuccess.value = true
        }
      })
    }

    const successBeforeEnter = (el) => {
      gsap.set(el, { autoAlpha: 0 })
    }

    const successEnter = (el, done) => {
      gsap.to(el, { duration: 1, autoAlpha: 1, onComplete: done })
    }

    return { onFormSubmit, formSubmitted, formLeave, showSuccess, successEnter, successBeforeEnter, formData }
  }
}
</script>

<style scoped lang="scss">
.report-issue-container {

  margin: 0 auto;

}
</style>
